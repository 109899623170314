import clsx from 'clsx';
import Link from 'next/link';
import type { FC, ReactNode } from 'react';
import { Image } from '@/components/raven/Image';
import { decodeEntities } from '@/utils/post-helpers';

import styles from './styles.module.css';

export type NonLinkLabelProps = {
	className?: string;
	icon?: ReactNode;
	media?: {
		alt: string;
		caption: string;
		id: string;
		url: string;
	};
	text: string;
	title?: string;
	variant?: 'filled' | 'informational' | 'outlined' | 'recipe';
};

export type LinkLabelProps = {
	href: string;
	variant?: 'filled' | 'outlined';
} & Omit<NonLinkLabelProps, 'variant'>;

export type LabelProps = {
	dark?: boolean;
} & (LinkLabelProps | NonLinkLabelProps);

const isLinkLabel = (props: LabelProps): props is LinkLabelProps => {
	return (
		'href' in props &&
		(!props.variant ||
			props.variant === 'filled' ||
			props.variant === 'outlined')
	);
};

export const Label: FC<LabelProps> = (props) => {
	const { className, icon, media, text, title, variant } = props;

	const cx = clsx(styles.label, className, variant && styles[variant], {
		[styles.isDefault]: !variant,
	});

	const labelBlock = (
		<>
			{media && (
				<Image
					className={styles.media}
					{...media}
					alt={media.alt}
					height={36}
					src={media.url}
					width={45}
				/>
			)}
			{icon}
			{title && <strong>{decodeEntities(title)}</strong>}
			{decodeEntities(text)}
		</>
	);

	const { href } = props as LinkLabelProps;
	return isLinkLabel(props) ? (
		<Link className={cx} href={href}>
			{labelBlock}
		</Link>
	) : (
		<span className={cx}>{labelBlock}</span>
	);
};
